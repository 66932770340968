  .content-wrapper {
    background-color: #fff !important;
  }
  .navbar {
    padding: 1rem 1rem;
  }

  .radio-inline label {
    padding-right: 2rem;
  }

  .form-group {
    padding-bottom: 1rem;
  }

  label:not(.form-check-label):not(.custom-file-label) {
      font-weight: 400;
  }

  .page-item.active .page-link {
      z-index: 3;
      color: #fff;
      background-color: green;
      border-color: green;
  }

  .shadow-bar {
     -webkit-box-shadow: 0px 14px 26px -15px rgba(51,51,51,0.18);
      -moz-box-shadow: 0px 14px 26px -15px rgba(51,51,51,0.18);
      box-shadow: 0px 14px 26px -15px rgba(51,51,51,0.18);

  }
  .brand-link {
    padding: 1.3rem 1.6rem;
    color: #fff;
  }
  .nav-sidebar .nav-item:hover {
    border-left: #3fa435 3px solid;
  }
  .nav-link {
    padding: 0.9rem 1rem;
  }
  h1,h2,h3,h4,h5,h6, .brand-text {
    font-family: Nunito;
  }

  .sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active {
    background-color: #323232;
    border-left: #3fa435 3px solid;
  }

  // triangle

.triangle {
  position: relative;
  background: #ffffff;
  padding: 15px;
  border: 1px solid #dddddd;
  margin-top: 20px;
}

.triangle:before,
.triangle:after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 88%;
  border: 11px solid transparent;
  border-bottom-color: #dddddd;
}

.triangle:after {
  left: 88%;
  border: 10px solid transparent;
  border-bottom-color: #ffffff;
}

.direct-chat-messages {
  height:350px;
  overflow:auto; 
  display:flex; 
  flex-direction:column-reverse;
}

.nav-sidebar .nav-link > .right, .nav-sidebar .nav-link > p > .right {
    top: 1.1rem;
}

.blurry-text {
   text-shadow: 3px 3px 10px #1b1b1b;
   color: transparent;
}

.initial-avatar {
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: #295aa4;
    color: #fff;
    border-radius: 50%;
    height: 40px;
    width: 40px;
}



.box {
  position: relative;
  margin: 40px auto;
  width: 400px;
  height: 350px;
  background: #fff;
  border-radius: 2px;
}

.box::before,
.box::after {
  content: '';
  position: absolute;
  bottom: 10px;
  width: 40%;
  height: 10px;
  box-shadow: 0 5px 14px rgba(0,0,0,.7);
  z-index: -1;
  transition: all .3s ease-in-out;
}

.box::before {
  left: 15px;
  transform: skew(-5deg) rotate(-5deg);
}

.box::after {
  right: 15px;
  transform: skew(5deg) rotate(5deg);
}

.box:hover::before,
.box:hover::after {
  box-shadow: 0 2px 14px rgba(0,0,0,.4);
}

.box:hover::before {
  left: 5px;
}

.box:hover::after {
  right: 5px;
}

